import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取常住人口列表数据
  list: params => get('/residentArchives/list', params),
  listAll: params => get('/residentArchives/listAll', params),
  // 获取退伍军人列表数据
  veteranList: params => get('/specialGroup/veteran/list', params),
  // 根据id获取人口详情
  getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // 根据id获取退伍军人详情
  getveteranById: id => get(`/specialGroup/veteran/getById?id=${id}`),
  // 获取省的行政区划
  getRegionInfo: args => get(`/regionInfo/list?level=1`),
  // 获取市级的行政区划
  getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&&provinceCode=${provinceCode}`),
  // 获取区级的行政区划
  getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&&cityCode=${cityCode}`),
  // 获取县级的行政区划
  getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&&countyCode=${countyCode}`),
  // 根据id获取走访记录详情
  // getInterviewInfoById: residentId => get(`/interviewInfo/list?type='5'&&residentId=${residentId}`),
  getInterviewInfoRecords: params => get(`/interviewInfo/records`, params),
  // 获取所有小区
  getGridManagement: args => get('/scGridManagement/queryAll', args),

  /**
   * 插入/修改
   */
  // 新增人口
  addveteran: args => post('/specialGroup/veteran/add', args),
  // 根据id删除
  deleteveteranById: id => deletes(`/specialGroup/veteran/deleteById?id=${id}`),
  // 批量删除
  deleteveteranByIds: ids => deletes(`/specialGroup/veteran/ids?ids=${ids}`),
  // 根据id编辑人口信息
  updateveteran: args => post('/specialGroup/veteran/update', args),
  // 新增走访记录
  addInterviewInfo: args => post('/interviewInfo/add', args),
  // 导出
  exports: args => downFile('/specialGroup/veteran/exportXls', args),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
